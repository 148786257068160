import React        from 'react';
import PropTypes    from 'prop-types';
import Flux         from '../../flux/Flux';
import Translations from '../../utils/Translations';

class SurveyFeedbackButton extends React.Component {

	constructor(props) {
		super(props);

		this.onButtonClick = this.onButtonClick.bind(this);
	}

	onButtonClick(e) {
		if (typeof e === 'object') {
			e.preventDefault();
			e.stopPropagation();
		}

		if (typeof this.props.callback === 'function') {
			this.props.callback();
		}

		Flux.Survey.openSurvey(Flux.Constants.SurveyTypes.FEEDBACK);

		if (this.props.isMobile) {
			Flux.VXMobile.closeMenu(Flux.Constants.VXMobile.Menus.NAVI);
		}
	}

	render() {
		let content;

		if (this.props.isMobile) {
			content = (
				<div className="navigation-navigation__item -is-static" onClick={this.onButtonClick}>
					<span>{Translations.get('FooterLabelFeedback')}</span>
				</div>
			);
		} else {
			content = (
				<div>
					<div className="btn--icon -icon-faq-full -btn-color-footer -height-footer -click-material h-width-full"
					     onClick={this.onButtonClick}
					>
						{Translations.get('FooterLabelFeedback')}
					</div>
				</div>
			);
		}

		return content;
	}
}

SurveyFeedbackButton.propTypes = {
	isMobile: PropTypes.bool,
	callback: PropTypes.func,
};

SurveyFeedbackButton.defaultProps = {
	isMobile: false,
};

export default SurveyFeedbackButton;
